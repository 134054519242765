import Vue from "vue";
import VueRouter from "vue-router";

import Dashboard from "./dashboard";
import Store from "@/store/index";
import moment from 'moment';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...Dashboard,

    {
      path: "/",
      name: "Home",
      component: () => import("@/views/home/newPage.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Dashboard",
       
      },
    },
    
    {
      path: "/settings/session",
      name: "sessions",
      component: () => import("@/views/pages/setting/sessions.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/settings/academic-settings",
      name: "academic-settings",
      component: () => import("@/views/pages/setting/academic.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/settings/basic-settings",
      name: "basic-settings",
      component: () => import("@/views/pages/setting/basic.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/administration/classes",
      name: "classes",
      component: () => import("@/views/pages/administration/class.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/administration/subjects",
      name: "subjects",
      component: () => import("@/views/pages/administration/subject.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/administration/students",
      name: "student",
      component: () => import("@/views/pages/administration/student.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/administration/staff",
      name: "staff",
      component: () => import("@/views/pages/administration/staff.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    { 
      path: '/results/add', 
      name: "add-result",
      component: () => import("@/views/pages/result/Add.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    { 
      path: '/result/add/psychomotor/:class_id/:session_id/:term_id', 
      name: "create-psychomoto",
      component: () => import("@/views/pages/result/PsychomotorCreate.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    { 
      path: '/results/psychomotor', 
      name: "add-psychomoto",
      component: () => import("@/views/pages/result/Psychomotor.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    // { path: '/result/add/:class_id/:session_id/:subject_id/:term_id', component: require('./components/result/Create.vue').default },
    
   
    // { path: '/result/viewall/:class_id/:session_id/:term_id', component: require('./components/result/ViewAllResult.vue').default },
   
    { 
      path: '/results/view', 
      name: "view-result",
      component: () => import("@/views/pages/result/View.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    { 
      path: "/result/add/:class_id/:session_id/:subject_id/:term_id", 
      name: "create_result",
      component: () => import("@/views/pages/result/create.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    { 
      path: '/result/view/all/:class_id/:session_id/:term_id', 
      name: "view_result_all",
      component: () => import("@/views/pages/result/ViewResultAll.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    { 
      path: '/result/view/annual/:student_id/:session_id/:term_id',
      name: "view_annual",
      component: () => import("@/views/pages/result/ViewAnnual.vue"), 
      meta: {
        requiresAuth: true,
      },
    },
    
    { 
      path: '/result/view/:student_id/:session_id/:term_id', 
      name: "view_result",
      component: () => import("@/views/pages/result/ViewResult.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    
    {
      path: "/enroll/student",
      name: "enroll",
      component: () => import("@/views/pages/administration/enroll.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/enroll/staff",
      name: "staffenroll",
      component: () => import("@/views/pages/administration/staffenroll.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    // {
    //   path: "/enroll/students",
    //   name: "mass-enroll",
    //   component: () => import("@/views/pages/administration/massenroll.vue"),
    //   meta: {
    //     requiresAuth: true,
    //   },
    // },

    {
      path: "/administration/student/:student_id",
      name: "student-profile",
      component: () => import("@/views/pages/administration/studentview.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/administration/staff/:staff_id",
      name: "student-profile",
      component: () => import("@/views/pages/administration/staffview.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/administration/student/edit/:student_id",
      name: "enroll",
      component: () => import("@/views/pages/administration/enroll.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/administration/staff/edit/:staff_id",
      name: "staffenroll",
      component: () => import("@/views/pages/administration/staffenroll.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/class/subject",
      name: "subjectClass",
      component: () => import("@/views/pages/class/subject.vue"),
      meta: {
        requiresAuth: true,
      },
    },


    {
      path: "/class/form",
      name: "formClass",
      component: () => import("@/views/pages/class/form.vue"),
      meta: {
        requiresAuth: true,
      },
    },


    {
      path: "/attendance/report",
      name: "attendanceReport",
      component: () => import("@/views/pages/attendance/report.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/staff/attendance/report",
      name: "staffAttendanceReport",
      component: () => import("@/views/pages/attendance/staffreport.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/attendance/barcodescan",
      name: "barcode",
      component: () => import("@/views/pages/attendance/barcode.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/staff/attendance/barcodescan",
      name: "staffBarcode",
      component: () => import("@/views/pages/attendance/staffbarcode.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/attendance/student/:student_id",
      name: "student-attendance",
      component: () => import("@/views/pages/attendance/studentattendance.vue"),
      meta: {
        requiresAuth: true,
      },
    },


    {
      path: "/student/result",
      name: "studentResult",
      component: () => import("@/views/pages/students/result.vue"),
      meta: {
        requiresAuth: true,
      },
    },


    {
      path: "/login",
      name: "login",
      component: () => import("@/views/authentication/login.vue"),
      meta: {
        layout: "full",
      },
    },

    {
      path: "/password",
      name: "change-password",
      component: () => import("@/views/authentication/password.vue"),
    },

    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/pricing-plan",
      name: "pricing-plan",
      component: () => import("@/views/error/plan.vue"),
    },

    {
      path: "/pricing-plan/:plan_id",
      name: "pricing-plan",
      component: () => import("@/views/error/plan.vue"),
    },

    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let x = Store.getters["Auth/authenticationStatus"];
    let user;
    let localUser = localStorage.getItem("user")
    if (localUser){
      user = JSON.parse(localUser);
    }

    if (x || user) {
      next();
      return;
    } else {
      next("/login");
    }
  } else {
    next();
  }
});


Vue.filter('capitalize', function(value) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('formatPrice', function(value) {
  if (!value) return '';
  let val = (value).toFixed(2).replace(',', '.')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
});

Vue.filter('myDate', function(created) {
  if (!created) return '';
  return moment(created).format('MMMM Do YYYY');
});

Vue.filter('setDate', function(created) {
  if (!created) return '';
  return moment(created).format('MMMM Do YYYY, h:mm:ss a'); // May 3rd 2021, 12:45:50 pm
 // return moment(created).format('MMMM Do YYYY');
});

Vue.filter('ageDate', function(created) {
  if (!created) return '';
  return moment(created, "YYYYMMDD").fromNow();

});

Vue.filter('toCurrency', function(value) {
if (typeof value !== 'number') {
  return value;
}
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
});
return formatter.format(value);
});


// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  // ;
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
