<template>
  <div
    class="overlay"
  >
    <div class="modal">
      <div class="modal__title">
        <span>Session Expired</span>
      </div>
      <div class="p-3"> 
    		<p>You have left this browser idle for 10 minutes.</p>
    		<p>{{ time }} second left</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
	data() {
		return {
			time: 10
		}
	},
	created() {
        let timerId = setInterval(() => {
            this.time -= 10;
            if(this.$route.name!='login') {
                if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);
                if (this.time < 1) {
                    clearInterval(timerId);
                    // Your logout function should be over here
                    localStorage.removeItem('user');
                    this.$router.push({ name: "login" });
                }
            }
        }, 1000);
    }
};
</script>