import Vue from "vue";

import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
require("@core/assets/fonts/feather/iconfont.css");

import VueExcelEditor from 'vue-excel-editor'
Vue.use(VueExcelEditor)

import router from "./router";
import IdleVue from "idle-vue";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

import swal from 'sweetalert2';
window.Swal = swal;


import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';

//global registration
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
Vue.use(VueFormWizard)

import Print from 'vue-print-nb'
// Global instruction 
Vue.use(Print);

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(VueSweetalert2);

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

Vue.use(IdleVue, {
  eventEmitter: new Vue(),
  store,
  idleTime: 3600000, // 3 seconds
  startAtIdle: false
});

Vue.mixin({
  methods: {
    roleHelper: function (x) {
   
      let type = JSON.parse(localStorage.getItem('user')).data.user.type;
     
      if (x.includes(type)) {
        
      }
      else {
        router.push('/error-404')
      }
    },
    
    featureHelper: function (x) {
      let features = JSON.parse(localStorage.getItem('user')).data.school.features;

      if (features.includes(x)) {
        
      }
      else {
        router.push('/pricing-plan/'+x)
      }
    },
  },
})

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
