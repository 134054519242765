/*eslint-disable*/

import axios from '@axios'

export function login(payload) {
  return axios.post(`/login`, payload.query);
}
export function refreshToken() {
  return axios.post(`/refresh`);
}
export function logout() {
  return axios.post(`/logout`);
}
